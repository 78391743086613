

export const loadStorage = (k:string) => {
	const v = localStorage.getItem(k);
	return v;
	// return v ? JSON.parse(v) as T : null;
};

// export const saveStorage = (k:string, v:Record<string,any>) => {
// 	localStorage.setItem(k, JSON.stringify(v));
// };


export const saveStorage = (k:string, v:string) => {
	localStorage.setItem(k, v);
};