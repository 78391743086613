import { FC } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

export const RouterView: FC<{
	routes:{
		path:string;
		component:any;
	}[];

}> = props => {
	const droutes = props.routes.map(r => {
		const { path, component: Component } = r;
		return (
			<Route exact path={path} key={r.path}>
				<Component />
			</Route>
		)
	});
	return (
		<Switch>
			{ droutes }
			<Redirect to="/" />
		</Switch>
	);
};