import { FC } from "react";
import { Box } from "@ui";

const Page:FC = () => {
	return (
		<div>
			TODO: Visual ffmpeg command builder
		</div>
	);
};

export default Page;