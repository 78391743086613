import { FC, useState } from "react";
import * as Utils from "./utils";
import * as Manifest from "./manifest";
import { GUID } from "../@utils";
import {
	BitrateMode,
	ResolutionPresets,
	VideoData,
	SegmentType,
	GPUMode,
	H264Preset,
} from "./types";
import { SForm, FormProp } from "./SForm";
import { useMemo } from "react";
import { CodeBlock } from "./CodeBlock";
import * as Constants from "./constants";
import styled from "styled-components";
import { getRandomInsult } from "./misc";
import { useEffect } from "react";
import * as Storage from "./storage";

enum Shorthands {
	Res4K = "3840x2160",
	Res6K = "5760x3240",
	Res8K = "7680x4320",
}

type ConfigValues = {
	"source_video.path":string,
	"source_video.resolution":string;
	"source_video.framerate":number;
	"source_video.bitrate":number;
	"encoding.h264_preset":string;
	"variants.sizes":string;
	"variants.initial_bitrate_factor":number;
	"variants.bitrates":string;
	"stream.buffer_scale":number;
	"stream.segment_length":number;
	"stream.segment_type":string;
	"constraints.skip_variants":string[];
	"job.hardware_acceleration":string;
	"output.overwrite_output":boolean;
};

const defaultValues:ConfigValues = {
	"source_video.path":"",
	"source_video.resolution":Shorthands.Res4K,
	"source_video.bitrate":20 * 1000000,
	"source_video.framerate":24,
	"encoding.h264_preset":H264Preset.Slow,
	"variants.sizes":ResolutionPresets.Video360,
	"variants.bitrates":BitrateMode.SimpleSpaced2,
	"variants.initial_bitrate_factor":0.75,
	"stream.buffer_scale":1.5,
	"stream.segment_length":1,
	"stream.segment_type":SegmentType.MPEGTS,
	"constraints.skip_variants":[],
	"job.hardware_acceleration":GPUMode.None,
	"output.overwrite_output":true,
};

type PartialRecord<K extends keyof any, T> = {
	[P in K]?: T;
};

type ValueValidators = (v:any) => boolean;

const valueValidators:PartialRecord<keyof ConfigValues, ValueValidators> = {
	"constraints.skip_variants":(v:any) => {
		return Array.isArray(v);
	}
};

type DrawerValues = Omit<ConfigValues, "constraints.skip_variants">;

type PropMap = Record<keyof DrawerValues,FormProp>;

const formProps:PropMap = {
	"source_video.path":{
		placeholder:"video.mp4, https://mysite.com/myvideo.mp4...",
		tooltip:"File path or remote URL"
	},
	"source_video.resolution":{
		options:Constants.resolutionOptions,
		tooltip:"Expected resolution of input"
	},
	"source_video.framerate":{
		options:Constants.framerateOptions,
		tooltip:"Expected framerate of input"
	},
	"source_video.bitrate":{
		type:"number",
		presets:Constants.bitratePresets,
		tooltip:"Highest quality BR (assumed to be less or equal to that of input file)"
	},
	"encoding.h264_preset":{
		options:Constants.avcPresetOptions,
		tooltip:`
		-preset <mode>
		<br/>
		Encoder preset
		`
	},
	"variants.sizes":{
		options:Constants.sizeModeOptions,
		tooltip:`
		Resolution selection based on input size
		`,
	},
	"variants.bitrates":{
		options:Constants.bitrateOptions,
		tooltip:`
		How bitrate variants
		`,
	},
	"variants.initial_bitrate_factor":{
		tooltip:`
		Used to compute highest bitrate in each resolution tier based on bitrate and size of input bitrate and size
		<br/>
		br = ((w * h) / (qw * qh))^factor * qbr
		`,
		min:0.4,
		max:0.9,
		step:0.01,
		labelFormatter:(v) => `${v}`,
	},
	"stream.buffer_scale":{
		tooltip:`
		-bufsize <bitrate * scale>
		<br/>
		Optimal range (may vary by content): 1-2
		`,
		min:1,
		max:3,
		step:0.1,
		labelFormatter:(v) => `${v} x Max BR`,
	},
	"stream.segment_length":{
		// tooltip:"stuff",
		tooltip:`
		-hls_time <seconds>
		<br/>
		Minimum fragment length.
		`,
		min:1,
		max:10,
		step:0.1,
		labelFormatter:(v) => `${v}s`,
	},
	"stream.segment_type":{
		tooltip:`
		-hls_segment_type <type>
		<br/>
		fMP4 output is supported by both DASH and HLS, although platform support may vary
		`,
		options:Constants.segmentTypeOptions
	},
	"job.hardware_acceleration":{
		tooltip:`
		-hwaccel <mode> <br/>
		--c:v h264_nvenc <br/>
		Hardware dependent. Using encoder mode requires h264_nvenc.
		<br/>
		Large jobs require more VRAM.`,
		options:Constants.gpuOptions,
	},
	"output.overwrite_output":{
		type:"toggle",
		tooltip:"ffmpeg -y ..."
	}
};

const storageKey = "streaming-ladder-settings";

const sanitizeSettings = (map:Record<string,any>) => {
	const r:Record<string,any> = {};
	const validKeys = Object.keys(defaultValues);
	Object.keys(map)
	.forEach(k => {
		if(!validKeys.includes(k)){ return; }
		const validator = valueValidators[k as keyof ConfigValues];

		let valid = validator ? validator(map[k]) : true;

		if(!validator){
			const type = typeof(map[k]);
			const ctype = typeof(defaultValues[k as keyof ConfigValues]);
			valid = type === ctype;
		}

		if(valid){
			r[k] = map[k];
		}
	});
	return r;
};


const loadSavedSettings = ():ConfigValues => {
	const sv = Storage.loadStorage(storageKey);
	const values = sv ? sanitizeSettings(JSON.parse(sv)) : {};
	return {
		...defaultValues,
		...values,
	};
};

export const StreamingLadder:FC = () => {

	const getVariantIdentifier = (v:VideoData) => {
		return `s=${v.w}x${v.h},br=${v.br},fr=${v.fr}`;
	};

	const isExcluded = (v:VideoData) => {
		const k = getVariantIdentifier(v);
		const ev = parsedFormData["constraints.skip_variants"];
		const i = ev.findIndex(vv => vv === k);
		return i > -1;
	};

	const ivalue = JSON.stringify(loadSavedSettings());

	const [ formData, setFormData ] = useState(ivalue);

	const [ lastSave, setLastSave ] = useState(0);

	const parsedFormData = useMemo<ConfigValues>(() => {
		return JSON.parse(formData);
	}, [ formData ]);

	const mfConfig = useMemo(() => {
		const [ w, h ] = parsedFormData["source_video.resolution"]
		.split("x")
		.map(v => Number(v));
		return {
			input:{
				w,
				h,
				br:parsedFormData["source_video.bitrate"],
				fr:parsedFormData["source_video.framerate"]
			},
			sizeMode:parsedFormData["variants.sizes"],
			bitrateMode:parsedFormData["variants.bitrates"],
		};
	}, [ parsedFormData ]);

	const variants = useMemo(() => {

		const brFactor = parsedFormData["variants.initial_bitrate_factor"];

		return Utils.getVideoVariants({
			source:mfConfig.input,
			scaleMode:mfConfig.sizeMode,
			brMode:mfConfig.bitrateMode,
			brFactor:brFactor,
			floor:300
		})
	}, [ mfConfig ]);

	const filteredVariants = useMemo(() => {
		return variants.filter(v => {
			return !isExcluded(v);
		})
	}, [ variants ]);

	const totalVariantKeys = useMemo<string[]>(() => {
		return variants.map(v => getVariantIdentifier(v));
	}, [ parsedFormData["constraints.skip_variants"] ]);

	const configPreview = useMemo(() => {
		const skipped = parsedFormData["constraints.skip_variants"].filter(v => {
			return totalVariantKeys.includes(v);
		});
		return JSON.stringify({
			...parsedFormData,
			"constraints.skip_variants":skipped,
		}, null, "\t");
	}, [ parsedFormData ]);

	const saveHandler = () => {
		const t =  Date.now();
		if(t < lastSave + 100){ return; }
		Storage.saveStorage(storageKey, formData);
		setLastSave(t);
	};

	useEffect(() => saveHandler(), [ formData ])

	const toggleExcludedVariant = (v:VideoData) => {
		const ev = parsedFormData["constraints.skip_variants"];
		const k = getVariantIdentifier(v);
		const i = ev.findIndex(vv => vv === k);
		if(i < 0){
			ev.push(k);
		}
		else {
			ev.splice(i, 1);
		}
		handleOnValues(parsedFormData);
	};

	const handleOnValues = (v:any) => {
		setFormData(JSON.stringify(v));
	};

	const mfcmd = Manifest.getManifestCommand({
		videoPath:parsedFormData["source_video.path"],
		variants:filteredVariants,
		segmentLength:parsedFormData["stream.segment_length"],
		bufferScale:parsedFormData["stream.buffer_scale"],
		segmentType:parsedFormData["stream.segment_type"],
		preset:parsedFormData["encoding.h264_preset"],
		gpuMode:parsedFormData["job.hardware_acceleration"],
		bitrateFactor:parsedFormData["variants.initial_bitrate_factor"],
		flags:{
			overwriteOutput:parsedFormData["output.overwrite_output"]
		}
	});

	const headers = [
		"Resolution",
		"Target Bitrate (bps)",
		"Framerate",
		"BPP (Bits Per Pixel)",
		"Skip",
	];

	const theaders = headers.map(ht => {
		return (
			<th key={ GUID.getGUID() } className="text-center">
				{ ht }
			</th>
		);
	});

	const trows = variants.map(v => {
		const bpp = Utils.calculateBPP(v.w, v.h, v.br, v.fr);

		const skip = isExcluded(v);

		const cols = [
			`${v.w} x ${v.h}`,
			Utils.formatBitrate(Utils.roundValue(v.br, 0)),
			v.fr,
			Utils.roundValue(bpp, 3),
			(
				<div className="d-flex">
					<input
					className="form-check-input shadow-none mx-auto"
					type="checkbox"
					checked={ skip }
					onChange={ () => toggleExcludedVariant(v) }
					/>
				</div>
			)
		]
		.map(c => (
			<td key={ GUID.getGUID() }>
				{ c }
			</td>
		));

		const cls = `${ skip ? "muted" : "" }`;

		return (
			<LadderRow
			key={ GUID.getGUID() }
			className={ cls }
			>
				{ cols }
			</LadderRow>
		);
	});

	return (
		<div>

			<SForm
			form={ formProps }
			values={ parsedFormData }
			onChange={ handleOnValues }
			/>

			<div className="my-3"/>
			<table className="table table-bordered border-dark">
				<thead>
					<tr>
						{ theaders }
					</tr>
				</thead>
				<tbody>
					{ trows }
				</tbody>
			</table>

			<CodeBlock
			text={ filteredVariants.length > 0 ? mfcmd : `# ${getRandomInsult().toLowerCase()}` }
			/>

			<CodeBlock
			text={ configPreview }
			/>

		</div>
	);
};


const LadderRow = styled.tr`


	&.muted {

		td {
			background:rgba(0,0,0,0.05);
		}

		td:not(:last-child){
			/* opacity:0.3; */
			color:rgba(0,0,0,0.3);
			/* text-decoration:line-through; */
		}
		

	}


`;