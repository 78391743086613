import { FC } from "react";
import { Box } from "@ui";
import { BrowserRouter as Router } from "react-router-dom";
import { RouterView } from "./RouterView";
import { routes } from "./routes";

export const App:FC = () => {
	return (
		<div className="container py-3">
			<Router>
				<RouterView routes={routes}/>
			</Router>
		</div>
	);
};