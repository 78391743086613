import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import { reportWebVitals } from "./reportWebVitals";
import { GlobalStyles } from "./GlobalStyles";
import ReactTooltip from "react-tooltip";


import "bootstrap/dist/js/bootstrap.bundle.min.js";

import "bootstrap/dist/css/bootstrap.css";
import "@mdi/font/css/materialdesignicons.min.css";
// declare var bootstrap: any;

// var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
// var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
//   return new bootstrap.Tooltip(tooltipTriggerEl)
// })


ReactDOM.render(
	<React.StrictMode>
		<GlobalStyles/>
		{/* <ReactTooltip/> */}
		<App/>
	</React.StrictMode>,
	document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
