import Home from "@pages/index";
import StreamingLadder from "@pages/streaming-ladder";
import FFMPEG from "@pages/ffmpeg-builder";

export enum RoutePath {
	Home = "/",
	StreamingLadder = "/build-streaming-ladder",
	FFmpegBuilder = "/build-ffmpeg-cmd",
}

export const routes:{
	path:string;
	component:any;
	label?:string;
}[] = [
	{
		path:RoutePath.Home,
		component:Home,
	},
	{
		label:"Build Streaming Ladder",
		path:RoutePath.StreamingLadder,
		component:StreamingLadder,
	},
	{
		label:"Build FFmpeg Command",
		path:RoutePath.FFmpegBuilder,
		component:FFMPEG,
	}
];