import { FC } from "react";
import { GUID } from "@utils";
import { Link as RouterLink } from "react-router-dom";
import { RoutePath, routes } from "../routes";

const Page:FC = () => {

	const links:{
		text:string;
		route:string;
		indent?:number;
	}[] = routes.filter(r => Boolean(r.label))
	.map(r => ({
		text:r.label || "",
		route:r.path,
	}));

	const twidth = 2;
	const dlinks = links.map(link => {
		const r = `${link.route}`;
		const indent = twidth * (link.indent || 0);
		return (
			<div
			key={ GUID.getGUID() }
			style={{
				paddingLeft:`${indent}em`
			}}
			>
				<RouterLink
				to={ r }
				>
					{ link.text }
				</RouterLink>
			</div>
		);
	});

	return (
		<div>
			{ dlinks }
		</div>
	);
};

export default Page;